import React from "react"
import Layout from "../components/layout"
import BackLink from "../components/backlink"
import SEO from "../components/seo"
import { graphql } from "gatsby"

class SubPage extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const siteTagline = data.site.siteMetadata.description
    const page = this.props.data.contentfulPosts
    return (
      <Layout
        location={this.props.location}
        title={siteTitle}
        tagline={siteTagline}
      >
        <SEO
          title={page.title}
          keywords={["blog", "gatsby", "javascript", "react"]}
        />
        <div className="post">
          <h1>{page.title}</h1>

          <div
            dangerouslySetInnerHTML={{
              __html: page.body.childMarkdownRemark.html,
            }}
          />

          <BackLink origin="story" />
        </div>
      </Layout>
    )
  }
}

export default SubPage

export const storyQuery = graphql`
  query storyQuery($slug: String!) {
    site {
      siteMetadata {
        title
        description
      }
    }
    contentfulPosts(slug: { eq: $slug }) {
      id
      title
      body {
        childMarkdownRemark {
          html
          excerpt(pruneLength: 320)
        }
      }
    }
  }
`
